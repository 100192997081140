/**
 * @module cookie
 * @description CookieManager provides ability to get/set/remove cookies in convenient way.
 * @example CookieManager.read('isRoadblockSeen', false);
 * @example CookieManager.write('isRoadblockSeen', 'yes', {days: 365});
 * @example CookieManager.remove('isRoadblockSeen');
 */

export class CookieManager {
    /**
     * Read cookie (without 'httpOnly' attribute) and returns cookie value
     *
     * @param {string} name Cookie name to read
     * @param {?string} defaultValue Default value to return if a given cookie variable if not exists
     * @returns {?string}
     */
    static read(name, defaultValue = null) {
        // To prevent the for loop in the first place assign an empty array
        // in case there are no cookies at all. Also prevents odd result when
        // calling "get()"
        /**
         * document.cookie is a string of the all cookies from the current page
         * separated by ' ;'.
         * @type {Array<string>}
         */
        const cookies = document.cookie ? document.cookie.split('; ') : [];

        for (const cookie of cookies) {
            if (cookie.indexOf(`${name}=`) === 0) {
                return cookie.substring(`${name}=`.length, cookie.length);
            }
        }

        return defaultValue;
    }

    /**
     * Whether a visitor has a given cookie
     *
     * @param {string} name Cookie name to read
     * @returns {boolean}
     */
    static has(name) {
        return this.read(name) !== null;
    }

    /**
     * @typedef {object} CookieOptions
     * @property {number} [days]
     * @property {boolean} [session]
     * @property {string} [path]
     * @property {string} [domain]
     * @property {boolean} [secure]
     * @property {string} [sameSite]
     */

    /**
     * Create new a cookie or overwrite existing
     *
     * @param {string} name Cookie name
     * @param {string|number|Array|null} value - cookie value
     * @param {CookieOptions} [options={}]
     * @returns {string} - full cookie string (including all parameters)
     */
    static write(name, value, options = {}) {
        options.days = options.days || 365;
        options.path = options.path || '/';

        let expires = '';
        if (!options.session) {
            const expiresDate = new Date();
            expiresDate.setMilliseconds(expiresDate.getMilliseconds() + options.days * 24 * 60 * 60 * 1000);
            expires = `; expires=${expiresDate.toUTCString()}`;
        }

        /** @type {string} */
        const fullCookieString = [
            name,
            '=',
            value,
            expires, // use expires attribute, max-age is not supported by IE
            `; path=${options.path}`,
            options.domain ? `; domain=${options.domain}` : '',
            options.secure && window.location.protocol === 'https:' ? `; Secure` : '',
            `; SameSite=${options.sameSite || 'Lax'}`,
        ].join('');
        document.cookie = fullCookieString;

        return fullCookieString;
    }

    /**
     * Remove existing cookie
     *
     * @param {string} name Cookie name
     * @returns {boolean} true if cookie removed, false if cookie was not exist.
     */
    static remove(name) {
        if (this.read(name) === null) {
            return false;
        }

        this.write(name, '', {
            days: -1,
        });

        return true;
    }
}
